* {
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
}

/* div {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none; 
                                 
} */

/*
START
prime react
*/
:root {
  --text-color:#495057;
  --text-color-secondary:#6c757d;
  --primary-color:#00699F;
  --primary-color-text:#ffffff;
}
.p-toast.p-toast-top-left, .p-toast.p-toast-top-right {
  top: 7rem;
}

.ql-snow .ql-tooltip {
  margin-left: 120px !important;
  }

.ql-container {
  font-size: 16px;
}

.p-editor-container .p-editor-content .ql-editor {
  background: inherit;
}

/* 
END
prime react
*/

a {
  color: #00699F;
}

p, .data {
  color:#333333
}

h3, .heading {
  color: #111111
}

textarea:focus:focus{
  outline: 0;
}

.buttonDimensions {
  width: 50px;
  height: 50px;
}

.bottomContainer {
  width: 400px;
}

.purple {
  color: #591F56;
}

.b--purple { 
  border-color: #591F56; 
}

.bg-purple { 
  background: #591F56; 
}

.primary {
  color: #00699F;
}

.b--primary { 
  border-color: #00699F; 
}

.bg-primary { 
  background: #00699F; 
}

.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1060
}

.selectedBox{
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, .4);
}

.bg-aubergine {
  background: #c2abc6;
}

.b--aubergine {
  border-color: #c2abc6;
}

.aubergine {
  color: #c2abc6;
}

.bg-secondary {
  background: #cce5f0;
}

.b--secondary {
  border-color: #cce5f0;
}

.secondary {
  color: #cce5f0;
}

.bg-thistle {
  background: #b977b3;
}

.navBarSide {
  height: 85vh;
  width: 18vw;
  overflow: auto;
  background: white;
}

.navSideColumn {
  margin-left: 1.5vw;
  margin-right: 0.5vw;
}

.image {
  margin-left: 1vw;
  margin-right: 1vw;
}

.navbar {
  position:sticky;
  width: 100%;
  height: 15vh;
  min-height: 90px;
  top:0;
  background: white;
}

.imageAndNavButtons {
  height: 84vh;
  width: 82vw;
}

.imageContainer {
  overflow: auto;
  margin-bottom: 10vh;
}

.prevNextScore {
  position: absolute;
  width: 82vw;
  height: 14vh;
  bottom: 0;
  background: white;
}

.bringToFront {
  z-index: 2
}

.ratingColumnWidth {
  width: 42px;
}

@keyframes blink {
  /**
   * At the start of the animation the dot
   * has an opacity of .2
   */
  0% {
    opacity: .2;
  }
  /**
   * At 20% the dot is fully visible and
   * then fades out slowly
   */
  20% {
    opacity: 1;
  }
  /**
   * Until it reaches an opacity of .2 and
   * the animation can start again
   */
  100% {
    opacity: .2;
  }
}

.nowLoading span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.saving span:nth-child(2) {
  animation-delay: .2s;
}

.saving span:nth-child(3) {
  animation-delay: .4s;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #cce5f0;
  color: #00699F;
  text-align: center;
  border-radius: 2px;
  padding: 5px 0;
  position: absolute;
  z-index: 20;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.workflow-config-step-title {
  flex: 1;
}

.workflow-config-step-value {
  flex: 3;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #cce5f0 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* wide tooltip */
.tooltipwide {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltipwide .tooltiptextwide {
  visibility: hidden;
  width: 200px;
  background-color: #cce5f0;
  color: #00699F;
  text-align: center;
  border-radius: 2px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltipwide .tooltiptextwide::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #cce5f0 transparent transparent transparent;
}

.tooltipwide:hover .tooltiptextwide {
  visibility: visible;
  opacity: 1;
}

.tox-sidebar-wrap {
  position: sticky !important;
  border-style: none !important;
}

.actionItem:hover {
  background-color: #dddddd;
}

.grow { 
  transition: all .2s ease-in-out; 
  }
  
.grow:hover { 
  transform: scale(1.05); 
}

/* tr:nth-child(even){background-color: #f2f2f2;} */

.flip {
  transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
  height: 0px;
}

.task-list table {
  border-spacing: 0;
  border: 1px solid #555555;
  
}

.task-list th, .task-list td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #555555;
    border-right: 1px solid #555555;
}

.task-list th:last-child {
  border-right: 0;
}

.task-list td:last-child {
  border-right: 0;
}

.task-list tr:nth-child(odd){background-color: #f2f2f2;}

.task-list thead tr:first-child{ background-color: #cce5f0; }

.viewtask-tab-table table, 
.viewtask-tab-table th, 
.viewtask-tab-table tr, 
.viewtask-tab-table td {
  border: 1px solid #00699F;
  border-collapse: collapse;
}

.viewtask-tab-table th, 
.viewtask-tab-table td {
  padding: 5px;
}

.tox-tinymce {
  margin-left: -12px !important;
  z-index: 10;
}

.toolbarmockimage {
  position:fixed;
  z-index: 9;
  top: 200px;
  left: 171px;
}

.mce-content-body {
  outline: none !important;
}

.bg-solid-purple {
  background: #6b3568; 
}

.bg-solid-primary {
  background: #057cb9; 
}

.bg-light-secondary {
  background: #eaf8ff;
}

/* content responsive alignment with breadcrumb */
.breadcrumb-back-width {
  width: 173px;
}  

.breadcrumb-home-margin-left {
  margin-left: 173px;
} 

.breadcrumb-home-alignment {
  margin-left: 173px;
} 

@media only screen and (max-width: 800px) {
  .project-page-heading {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .breadcrumb-back-width {
    width: 100px;
  }
  .breadcrumb-home-margin-left {
    margin-left: 100px;
  }
  .breadcrumb-home-alignment {
    margin-left: 16px;
  }
  .test {
    flex-direction: column;
  } 
}

@media only screen and (max-width: 800px) {
  .project-page-heading {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .breadcrumb-back-width {
    width: 100px;
  }
  .breadcrumb-home-margin-left {
    margin-left: 100px;
  }
  .breadcrumb-home-alignment {
    margin-left: 16px;
  }
  .flex-direction-column {
    flex-direction: column;
    gap: 1rem !important;
    padding-top: 1rem;
    text-align: center;
  } 
}

